import {
  PostCardFragmentFragment,
  RepostCardFragmentFragment,
} from '@/__generated__/graphql.ts';
import { Link } from '@/routes-utils/navigation.tsx';
import { paths } from '@/routes-utils/paths.ts';
import { EmptyState } from '@synoptic/ui-kit/empty-state/empty-state.tsx';
import { TrashIcon } from '@synoptic/ui-kit/icons/react/trash.tsx';
import { formatDateWithMs } from '../../date-utils/format-date-with-ms.ts';
import { formatDate } from '../../date-utils/format-date.ts';
import { TextEditor } from '../text-editor/text-editor.tsx';
import { PostCardMedia } from './post-card-media/post-card-media.tsx';
import { PostCardPreview } from './post-card-preview/post-card-preview.tsx';
import {
  postCardBody,
  postCardContent,
  postCardDate,
  postCardDeletedPlaceholder,
} from './post-card.css.ts';

export const PostCardContent = ({
  post,
}: {
  post: PostCardFragmentFragment | RepostCardFragmentFragment;
}) => {
  const postContent =
    (post as RepostCardFragmentFragment)?.originalPost || post;

  const quotedPost =
    (postContent.quotedPost as RepostCardFragmentFragment)?.originalPost ||
    postContent.quotedPost;

  const isDeleted = Boolean(postContent.deletedAt);

  return (
    <div className={postCardContent}>
      {isDeleted ? (
        <EmptyState
          illustration={<TrashIcon />}
          heading="The post was deleted"
          className={postCardDeletedPlaceholder}
        />
      ) : (
        <>
          <TextEditor
            namespace={`post:${postContent.id}`}
            className={postCardBody}
            initialState={postContent.json || postContent.text}
            editable={false}
            charLimit={330}
            withShowMore
          />

          <PostCardMedia
            media={postContent.media}
            authorId={postContent.author.id}
          />
        </>
      )}

      {quotedPost?.id && (
        <Link
          to={paths.makePost(quotedPost.id)}
          onClick={(e) => e.stopPropagation()}
        >
          <PostCardPreview
            post={quotedPost}
            hoverable={true}
            clickableAuthor={false}
          />
        </Link>
      )}

      <time
        title={formatDateWithMs(new Date(postContent.createdAt))}
        dateTime={postContent.createdAt}
        className={postCardDate}
      >
        {formatDate(new Date(postContent.createdAt))}
      </time>
    </div>
  );
};
