import {
  PostCardFragmentFragment,
  RepostCardFragmentFragment,
  TournamentPostCardFragmentFragment,
} from '@/__generated__/graphql.ts';
import { Link } from '@/routes-utils/navigation.tsx';
import { paths } from '@/routes-utils/paths.ts';
import { ChatBubbleOutlineIcon } from '@synoptic/ui-kit/icons/react/chat-bubble-outline.tsx';
import { PostTagButton } from '@synoptic/ui-kit/post-tag-button/post-tag-button.tsx';
import { useMe } from '../../routes/_app/use-me.ts';
import { TournamentLabel } from '../post-label/tournament-label.tsx';
import { PostCardShareActionsMenu } from './post-card-actions/post-card-share-actions-menu.tsx';
import { PostVoteButton } from './post-card-actions/post-vote-button.tsx';
import {
  postCardActions,
  postCardFooter,
  postCardLabels,
} from './post-card.css.ts';

export const PostCardFooter = ({
  post,
}: {
  post:
    | PostCardFragmentFragment
    | RepostCardFragmentFragment
    | TournamentPostCardFragmentFragment;
}) => {
  const me = useMe();

  const postContent =
    (post as RepostCardFragmentFragment)?.originalPost || post;

  const label =
    (postContent as unknown as TournamentPostCardFragmentFragment)?.tournament
      ?.label || undefined;

  const tournamentId = (
    postContent as unknown as TournamentPostCardFragmentFragment
  )?.tournament?.id;

  const isDeleted = Boolean(postContent.deletedAt);
  const isRepost = post.__typename === 'RePost';
  const isMyRepost =
    (isRepost && post.author?.id === me.id) ||
    (!isRepost && !!postContent.repostId);
  const repostId = isRepost ? post.id : postContent.repostId;

  return (
    <div className={postCardFooter}>
      <div className={postCardLabels} onClick={(e) => e.stopPropagation()}>
        {label ? (
          <TournamentLabel
            label={label}
            tournamentId={tournamentId}
            linkToTournament
          />
        ) : null}
      </div>
      <div className={postCardActions} onClick={(e) => e.stopPropagation()}>
        <PostVoteButton post={postContent} disabled={isDeleted} />

        <PostTagButton startIcon={<ChatBubbleOutlineIcon />} asChild>
          <Link to={paths.makePost(postContent.id)}>
            {postContent.commentCount}
          </Link>
        </PostTagButton>

        <PostCardShareActionsMenu
          post={postContent}
          isMyRepost={isMyRepost}
          repostId={repostId}
        />
      </div>
    </div>
  );
};
