import { useEffect, useState } from 'react';
import { useTimeout } from '@synoptic/ui-kit/utils/use-timeout.ts';
import { newPostsVar } from '@/components/post-card/new-post/new-posts-var.ts';
import { useCallbackRef } from '@synoptic/ui-kit/utils/use-callback-ref.tsx';

export const useHighlightNewPost = ({
  postId,
  if: condition = true,
}: {
  postId: string;
  if?: boolean;
}) => {
  const [isPostHighlighted, setIsPostHighlighted] = useState(
    condition && newPostsVar().includes(postId),
  );

  const removeHighlight = useCallbackRef(() => {
    setIsPostHighlighted(false);
    newPostsVar(newPostsVar().filter((id) => id !== postId));
  });

  useTimeout(
    () => {
      if (isPostHighlighted) {
        removeHighlight();
      }
    },
    isPostHighlighted ? 7000 : null,
  );

  useEffect(() => {
    return () => {
      removeHighlight();
    };
  }, [removeHighlight]);

  return isPostHighlighted;
};
