import { ComponentPropsWithoutRef, FC, useState } from 'react';
import { clsx } from 'clsx';
import { newPostBadge } from '@/components/post-card/new-post/new-post-badge.css.ts';

interface NewPostBadgeProps extends ComponentPropsWithoutRef<'div'> {
  hidden?: boolean;
  postsCount?: number;
}

export const NewPostBadge: FC<NewPostBadgeProps> = ({
  hidden,
  children,
  postsCount,
  className,
}) => {
  const [postsCountToShow, setPostsCountToShow] = useState(postsCount);
  // hack to not set and display 0, while badge smoothly disappears
  if (postsCount && postsCount > 0 && postsCountToShow !== postsCount) {
    setPostsCountToShow(postsCount);
  }

  const defaultLabel =
    postsCountToShow === undefined
      ? 'New post'
      : `${postsCountToShow} New post${postsCountToShow > 1 ? 's' : ''}`;

  return (
    <div
      className={clsx(newPostBadge, className)}
      data-hidden={hidden ? '' : undefined}
    >
      {children || defaultLabel}
    </div>
  );
};
