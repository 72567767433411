import { ReputationBet } from '@/components/reputation-bet/reputation-bet.tsx';
import {
  postCardReputationBet,
  postCardReputationBetContainer,
} from './post-card-reputation-bet.css.ts';

export const PostCardReputationBet = ({
  reputationBet,
}: {
  reputationBet: number;
}) => {
  return (
    <div className={postCardReputationBetContainer}>
      <div onClick={(e) => e.stopPropagation()}>
        <ReputationBet
          className={postCardReputationBet}
          reputationBet={reputationBet}
        />
      </div>
    </div>
  );
};
