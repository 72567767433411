import { memo } from 'react';
import {
  PostCardFragmentFragment,
  RepostCardFragmentFragment,
} from '@/__generated__/graphql.ts';
import { paths } from '@/routes-utils/paths.ts';
import { hasTextSelection } from '../../utils/has-text-selection.ts';
import { LayoutCard } from '../layout/card.tsx';
import { HiddenPost } from './hidden-post/hidden-post.tsx';
import { PostCardActionsMenu } from './post-card-actions/post-card-actions-menu.tsx';
import { PostCardContent } from './post-card-content.tsx';
import { PostCardFooter } from './post-card-footer.tsx';
import { PostCardHeader } from './post-card-header.tsx';
import { PostCardReputationBet } from './post-card-reputation-bet/post-card-reputation-bet.tsx';
import { PostCardTopHeader } from './post-card-top-header.tsx';
import { postCardContainer } from './post-card.css.ts';
import { useNavigate } from 'react-router';

type PostProps = {
  post: PostCardFragmentFragment | RepostCardFragmentFragment;
  linkToPost?: boolean;
};

export const PostCard = memo<PostProps>(function PostCard({
  post,
  linkToPost,
}) {
  const navigate = useNavigate();

  const postContent =
    (post as RepostCardFragmentFragment)?.originalPost || post;

  const isDeleted = Boolean(postContent.deletedAt);
  const isReleased = !postContent.releaseAt || Boolean(postContent.released);

  const reputationBet = postContent.reputationBet;

  return isReleased ? (
    <LayoutCard
      data-testid={'post-card'}
      className={postCardContainer}
      hoverable={linkToPost}
      data-deleted={isDeleted ? '' : undefined}
      onClick={(e) => {
        const isSelecting = hasTextSelection();
        if (!isSelecting) {
          if (linkToPost) navigate(paths.makePost(postContent.id));
        } else {
          e.preventDefault();
        }
      }}
      role={linkToPost ? 'link' : 'article'}
      tabIndex={linkToPost ? 0 : undefined}
    >
      <PostCardTopHeader post={post} />

      <PostCardHeader
        author={postContent.author}
        actions={
          <PostCardActionsMenu
            id={postContent.id}
            author={postContent.author}
            deletedAt={postContent.deletedAt}
          />
        }
      />

      <PostCardContent post={post} />

      {isDeleted ? null : (
        <PostCardReputationBet reputationBet={reputationBet} />
      )}

      <PostCardFooter post={post} />
    </LayoutCard>
  ) : (
    <HiddenPost post={postContent} withAuthor={true} />
  );
});
