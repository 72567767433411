import {
  PostCardFragmentFragment,
  RepostCardFragmentFragment,
  StreamPostCardFragmentFragment,
  TournamentPostCardFragmentFragment,
} from '../__generated__/graphql';
import { PostCard } from './post-card/post-card';
import { StreamPostCard } from './stream-post-card/stream-post-card';
import { TournamentPostCard } from './tournament-post-card/tournament-post-card';

// TODO: rename post-card to regular-post-card and rename this to post-card
export const PolymorphicPostCard = ({
  post,
  linkToPost,
}: {
  post:
    | PostCardFragmentFragment
    | RepostCardFragmentFragment
    | StreamPostCardFragmentFragment
    | TournamentPostCardFragmentFragment;
  linkToPost?: boolean;
}) => {
  const typename = post.__typename;
  switch (typename) {
    case 'StreamPost':
      return <StreamPostCard streamPost={post} linkToPost={linkToPost} />;
    case 'TournamentPost':
      return <TournamentPostCard post={post} linkToPost={linkToPost} />;
    case 'RegularPost':
    case 'RePost':
      return <PostCard post={post} linkToPost={linkToPost} />;
    default:
      throw new Error(`Unsupported post type: ${typename}`);
  }
};
