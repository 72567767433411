import { EmptyState } from '@synoptic/ui-kit/empty-state/empty-state.tsx';
import { TrashIcon } from '@synoptic/ui-kit/icons/react/trash.tsx';
import { clsx } from 'clsx';
import { FC } from 'react';
import { useMatch, useNavigate } from 'react-router';
import { StreamPostCardFragmentFragment } from '@/__generated__/graphql.ts';
import { formatDateWithMs } from '../../date-utils/format-date-with-ms.ts';
import { formatDate } from '../../date-utils/format-date.ts';
import { paths } from '@/routes-utils/paths.ts';
import { hasTextSelection } from '../../utils/has-text-selection.ts';
import { LayoutCard } from '../layout/card.tsx';
import { HiddenPost } from '../post-card/hidden-post/hidden-post.tsx';
import { PostCardShareActionsMenu } from '../post-card/post-card-actions/post-card-share-actions-menu.tsx';
import { PostVoteButton } from '../post-card/post-card-actions/post-vote-button.tsx';
import { PostCardHeader } from '../post-card/post-card-header.tsx';
import { PostCardHiddenLabel } from '../post-card/post-card-hidden-label.tsx';
import { PostCardMedia } from '../post-card/post-card-media/post-card-media.tsx';
import {
  postCardActions,
  postCardBody,
  postCardContainer,
  postCardContent,
  postCardDate,
  postCardDeletedPlaceholder,
  postCardFooter,
  postCardLabels,
} from '../post-card/post-card.css.ts';
import { StreamLabel } from '../post-label/stream-label.tsx';
import { TextEditor } from '../text-editor/text-editor.tsx';
import {
  streamHiddenPostCardContainer,
  streamPostCardActions,
  streamPostCardContainer,
  streamPostCardFooter,
  streamPostCardLabels,
  streamPostCardTopHeader,
} from './stream-post-card.css.ts';
import { useHighlightNewPost } from '@/components/post-card/new-post/use-highlight-new-post.ts';
import { NewPostBadge } from '@/components/post-card/new-post/new-post-badge.tsx';

export interface StreamPostCardProps {
  streamPost: StreamPostCardFragmentFragment;
  withAuthor?: boolean;
  cardContainer?: boolean;
  linkToPost?: boolean;
}

export const StreamPostCard: FC<StreamPostCardProps> = ({
  streamPost,
  withAuthor,
  linkToPost,
  cardContainer,
}) => {
  const isStreamDeck = Boolean(useMatch(paths.streamDeck));
  const navigate = useNavigate();

  const isDeleted = Boolean(streamPost.deletedAt);
  const isReleased = streamPost.released;
  const isPaidStream = !!streamPost.stream.paidStreamData;

  const Container = cardContainer ? LayoutCard : 'div';

  const isPostHighlighted = useHighlightNewPost({
    postId: streamPost.id,
    if: isStreamDeck,
  });

  return isReleased ? (
    <Container
      className={clsx(
        postCardContainer,
        !withAuthor && streamPostCardContainer,
      )}
      data-highlighted={isPostHighlighted ? '' : undefined}
      data-deleted={isDeleted ? '' : undefined}
      onClick={(e) => {
        const isSelecting = hasTextSelection();
        if (!isSelecting) {
          if (linkToPost) {
            navigate(paths.makePost(streamPost.id));
          }
        } else {
          e.preventDefault();
        }
      }}
      role={linkToPost ? 'link' : undefined}
      tabIndex={linkToPost ? 0 : undefined}
    >
      <NewPostBadge hidden={!isPostHighlighted} />

      {streamPost.releaseAt && (
        <div className={streamPostCardTopHeader}>
          <PostCardHiddenLabel />
        </div>
      )}
      {withAuthor ? <PostCardHeader author={streamPost.author} /> : null}

      <div className={postCardContent}>
        {isDeleted ? (
          <EmptyState
            illustration={<TrashIcon />}
            heading="The post was deleted"
            className={postCardDeletedPlaceholder}
          />
        ) : (
          <>
            <TextEditor
              namespace={`stream-post:${streamPost.id}`}
              className={postCardBody}
              initialState={streamPost.json || streamPost.text}
              editable={false}
              charLimit={330}
              withShowMore
            />

            <PostCardMedia
              media={streamPost.media}
              authorId={streamPost.author.id}
            />
          </>
        )}

        <time
          title={formatDateWithMs(new Date(streamPost.createdAt))}
          dateTime={streamPost.createdAt}
          className={postCardDate}
        >
          {formatDate(new Date(streamPost.createdAt))}
        </time>
      </div>

      <div className={clsx(streamPostCardFooter, postCardFooter)}>
        <div
          className={clsx(streamPostCardActions, postCardActions)}
          onClick={(e) => e.stopPropagation()}
        >
          <PostVoteButton post={streamPost} disabled={isDeleted} />
          <PostCardShareActionsMenu
            post={streamPost}
            quoteAllowed={!isPaidStream}
          />
        </div>
        <div
          className={clsx(postCardLabels, streamPostCardLabels)}
          onClick={(e) => e.stopPropagation()}
        >
          {streamPost.stream.label && streamPost.stream.id ? (
            <StreamLabel
              label={streamPost.stream.label}
              streamId={streamPost.stream.id}
              linkToStream
            />
          ) : null}
        </div>
      </div>
    </Container>
  ) : (
    <div
      className={clsx(postCardContainer, streamHiddenPostCardContainer)}
      data-highlighted={isPostHighlighted ? '' : undefined}
    >
      <NewPostBadge hidden={!isPostHighlighted} />
      <HiddenPost post={streamPost} withAuthor={withAuthor} />
    </div>
  );
};
